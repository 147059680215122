(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			OnepageScroll: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					// One page scroll
					if ( jQuery( 'body' ).hasClass( 'onepage-scroll' ) ) {
						onepageScroll();
					}

					// Set height for onepage-scroll
					function onepageScroll() {
						var $opScroll = jQuery( '.entry-content' );
						var $hWindows = jQuery( window ).height();
						$opScroll.onepage_scroll( {
							sectionContainer: '.onepage',
							loop: false,
							beforeMove: function( index ) {
								var currentSlide = $opScroll.find( '.onepage[data-index="' + index + '"]' );
								if ( currentSlide.hasClass( 'onepage-light' ) ) {
									jQuery( '#branding_logo img' ).attr( 'src', jsVar.logo_normal );
								} else {
									jQuery( '#branding_logo img' ).attr( 'src', jsVar.logo_light );
								}
							}
						} );
						$opScroll.css( 'height', $hWindows + 'px' );
						jQuery( window ).trigger( 'resize' );
					}
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
