(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			LightboxVideo: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '.insight-light-video, .insight-video' ).lightGallery();

					$( '.insight-video-btn, .insight-show-video' ).lightGallery( {
						selector: 'a'
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
