(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Gmaps: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					// maps
					jQuery( '.insight-gmaps' ).each( function() {
						var gmMapDiv = jQuery( this );
						if ( gmMapDiv.length ) {
							var gmMarkerAddress = gmMapDiv.attr( 'data-address' );
							var gmHeight = gmMapDiv.attr( 'data-height' );
							var gmWidth = gmMapDiv.attr( 'data-width' );
							var gmZoomEnable = gmMapDiv.attr( 'data-zoom_enable' );
							var gmZoom = gmMapDiv.attr( 'data-zoom' );
							var gmIcon = gmMapDiv.attr( 'data-icon' );
							var mapTypeId = gmMapDiv.attr( 'data-map_type_id' );
							var gmStyle = gmMapDiv.attr( 'data-style' );
							var gmContent = gmMapDiv.attr( 'data-content' );
							gmMapDiv.gmap3( {
								action: 'init',
								marker: {
									address: gmMarkerAddress,
									options: {
										icon: gmIcon
									},
									events: {
										click: function( marker, event ) {
											if ( gmContent.trim() != '' ) {
												var map = jQuery( this ).gmap3( 'get' );
												var infowindow = jQuery( this ).gmap3( {get: {name: 'infowindow'}} );
												if ( infowindow ) {
													infowindow.open( map, marker );
													infowindow.setContent( gmContent );
												}
												else {
													jQuery( this ).gmap3( {
														infowindow: {
															anchor: marker,
															options: {content: gmContent}
														}
													} );
												}
											}

										}
									}
								},
								map: {
									options: {
										zoom: parseInt( gmZoom ),
										zoomControl: true,
										mapTypeId: eval( mapTypeId ),
										mapTypeControl: false,
										scaleControl: false,
										scrollwheel: gmZoomEnable == 'enable' ? true : false,
										streetViewControl: false,
										draggable: true,
										styles: eval( gmStyle ),
									}
								}
							} ).width( gmWidth ).height( gmHeight );
						}
					} ); // end each
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
