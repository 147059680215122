function insight_404_height() {
	var wh = jQuery( window ).height();
	jQuery( 'body.error404 .content-area' ).height( wh );
}

function insight_go_back() {
	window.history.back();
}

(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Page404: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					insight_404_height();

					jQuery( '.content-404-back' ).on( 'click', function( e ) {
						e.preventDefault();
						insight_go_back();
					} );

					jQuery( window ).on( 'resize', function() {
						insight_404_height();
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
