(
	function( insight, $ ) {
		function insightOnReady() {

			if ( typeof insight.SearchBox !== 'undefined' ) {
				insight.SearchBox.init();
			}

			if ( typeof insight.HeaderSticky !== 'undefined' ) {
				insight.HeaderSticky.init();
			}

			if ( typeof insight.OverlayMenu !== 'undefined' ) {
				insight.OverlayMenu.init();
			}

			if ( typeof insight.LightboxVideo !== 'undefined' ) {
				insight.LightboxVideo.init();
			}

			if ( typeof insight.BlogMasonry !== 'undefined' ) {
				insight.BlogMasonry.init();
			}

			if ( typeof insight.ProgressBar !== 'undefined' ) {
				insight.ProgressBar.init();
			}

			if ( typeof insight.Counter !== 'undefined' ) {
				insight.Counter.init();
			}

			if ( typeof insight.Carousel !== 'undefined' ) {
				insight.Carousel.init();
			}

			if ( typeof insight.Accordion !== 'undefined' ) {
				insight.Accordion.init();
			}

			if ( typeof insight.LightboxGallery !== 'undefined' ) {
				insight.LightboxGallery.init();
			}

			if ( typeof insight.Page404 !== 'undefined' ) {
				insight.Page404.init();
			}

			if ( typeof insight.PageContact !== 'undefined' ) {
				insight.PageContact.init();
			}

			if ( typeof insight.ScrollUp !== 'undefined' ) {
				insight.ScrollUp.init();
			}

			if ( typeof insight.Gmaps !== 'undefined' ) {
				insight.Gmaps.init();
			}

			if ( typeof insight.Portfolio !== 'undefined' ) {
				insight.Portfolio.init();
			}

			if ( typeof insight.ColSticky !== 'undefined' ) {
				insight.ColSticky.init();
			}

			if ( typeof insight.PortfolioVerticalSlide !== 'undefined' ) {
				insight.PortfolioVerticalSlide.init();
			}

			if ( typeof insight.PortfolioList !== 'undefined' ) {
				insight.PortfolioList.init();
			}

			if ( typeof insight.PortfolioFilter !== 'undefined' ) {
				insight.PortfolioFilter.init();
			}

			if ( typeof insight.OnepageScroll !== 'undefined' ) {
				insight.OnepageScroll.init();
			}

			if ( typeof insight.Animate !== 'undefined' ) {
				insight.Animate.init();
			}

			if ( typeof insight.Extra !== 'undefined' ) {
				insight.Extra.init();
			}
		}

		$( document ).ready( function() {
			insightOnReady();
		} );
	}.apply( this, [window.insight, jQuery] )
);
