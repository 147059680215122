(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			BlogMasonry: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var $container = $( '.insight-blog-masonry' ).isotope( {
						itemSelector: '.masonry-item',
						percentPosition: true,
					} );

					setTimeout( function() {
						$container.isotope( 'layout' );
					}, 100 );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
