(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Extra: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.next-row' ).on( 'click', function() {
						jQuery( '.entry-content' ).moveDown();
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
