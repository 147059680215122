(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			ColSticky: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.col-sticky' ).stick_in_parent();
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
