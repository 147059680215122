(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Animate: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '.tm-animation' ).inViewport( function( px ) {
						if ( px ) {
							$( this ).addClass( 'animate' );
						}
					} );
					$( window ).trigger( 'scroll' );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
