(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PortfolioVerticalSlide: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					// One page scroll
					if ( jQuery( '.insight-portfolio-vertical-slide' ).length > 0 ) {
						portfolioScroll();
						jQuery( '.insight-portfolio-vertical-slide-item' ).each( function() {
							var _this_text = jQuery( this ).find( '.title-text' );
							var str_new = '<span class="word">';
							var str = _this_text.text();
							var res = str.split( '' );
							jQuery.each( res, function( i, val ) {
								var str_i = Math.floor( i % 3 ) + 1;
								if ( val == ' ' ) {
									str_new += '<span class="char_' + str_i + '"><span>&nbsp;</span></span></span><span class="word">';
								} else {
									str_new += '<span class="char_' + str_i + '"><span>' + val + '</span></span>';
								}
							} );
							str_new += '</span>';
							_this_text.html( str_new );
						} );
					}

					// Set height for onepage-scroll
					function portfolioScroll() {
						var $opScroll = jQuery( '.insight-portfolio-vertical-slide' );
						var $hWindows = jQuery( window ).height();
						$opScroll.onepage_scroll( {
							sectionContainer: '.insight-portfolio-vertical-slide-item',
							loop: true,
						} );
						$opScroll.css( 'height', $hWindows + 'px' );
					}
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
