(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PortfolioFilter: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.insight-portfolio-filter' ).find( '.img' ).each( function() {
						var img_width = jQuery( this ).width();
						jQuery( this ).height( img_width );
					} );

					// Portfolio filter
					jQuery( '.insight-portfolio-filter' ).each( function() {
						var this_ = jQuery( this );
						var this_filter = jQuery( this ).find( '.filter' );
						var this_items = jQuery( this ).find( '.items' );
						this_filter.find( 'a' ).on( 'click', function( e ) {
							this_filter.find( 'a' ).removeClass( 'active' );
							jQuery( this ).addClass( 'active' );
							var data_filter = jQuery( this ).attr( 'data-filter' );
							if ( data_filter == '*' ) {
								this_items.find( '.item' ).slideDown();
							} else {
								this_items.find( '.item' ).slideUp();
								this_items.find( '.' + data_filter ).slideDown();
							}
							e.preventDefault();
						} );
						var insightHoverTimer;
						var insightHoverDelay = 300;

						this_items.find( 'a' ).hover( function() {
							var old_img = this_.find( '.img' ).attr( 'src' );
							var data_img = jQuery( this ).attr( 'data-img' );
							var img_width = this_.find( '.img' ).width();
							this_.find( '.img-wrapper' ).attr( 'style', 'background-image: url(' + old_img + ')' );
							insightHoverTimer = setTimeout( function() {
								this_.find( '.img-inner' ).fadeOut( 300, function() {
									this_.find( '.img' ).attr( 'src', data_img );
								} ).fadeIn( 300, function(  ) {
									this_.find( '.img-wrapper' ).attr( 'style', 'background-image: none' );
								} );
								this_.find( '.img' ).height( img_width );
							}, insightHoverDelay );
						}, function() {
							clearTimeout( insightHoverTimer );
						} );
					} );

					jQuery( window ).on( 'resize', function() {
						var img_width = jQuery( '.insight-portfolio-filter' ).find( '.img' ).width();
						jQuery( '.insight-portfolio-filter' ).find( '.img' ).height( img_width );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
