(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			OverlayMenu: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '.open-overlay-menu' ).on( 'click', function( e ) {
						if ( $( this ).hasClass( 'active' ) ) {
							$( '.insight-overlay-menu' ).removeClass( 'active' );
							$( this ).removeClass( 'active' );
							$( 'body' ).removeClass( 'overflow-hidden' );
						} else {
							$( '.insight-overlay-menu' ).addClass( 'active' );
							$( this ).addClass( 'active' );
							$( 'body' ).addClass( 'overflow-hidden' );
						}
					} );

					$( '.insight-overlay-menu ul li a' ).on( 'click', function( e ) {
						var _self = $( this ),
							$li = _self.closest( 'li' ),
							$ul = $li.find( '.sub-menu' );

						if ( $li.hasClass( 'menu-item-has-children' ) ) {
							$ul.slideToggle();
							return false;
						}
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
