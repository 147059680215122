(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Portfolio: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					// infinity load
					jQuery( '.insight-load-infinity' ).waypoint( function( direction ) {
						if ( direction === 'down' ) {
							jQuery( '.insight-load-infinity span' ).addClass( 'loading' );
							var data = {
								action: 'portfolio_load',
								query: jQuery( '.insight-load-infinity input' ).val()
							};
							jQuery.post( jsVar.url, data, function( response ) {
								jQuery( '.insight-load-infinity span' ).removeClass( 'loading' );
								if ( response != '' ) {
									jQuery( '.insight-portfolio-archive .row' ).append( response );
									var query = jQuery.parseJSON( jQuery( '.insight-load-infinity input' ).val() );
									query.paged ++;
									jQuery( '.insight-load-infinity input' ).val( JSON.stringify( query ) );
								} else {
									jQuery( '.insight-load-infinity' ).remove();
								}
							} );
						}
					}, {
						offset: '100%'
					} );
					jQuery( '.insight-load-infinity span' ).on( 'click', function() {
						jQuery( '.insight-load-infinity span' ).addClass( 'loading' );
						var data = {
							action: 'portfolio_load',
							query: jQuery( '.insight-load-infinity input' ).val()
						};
						jQuery.post( jsVar.url, data, function( response ) {
							jQuery( '.insight-load-infinity span' ).removeClass( 'loading' );
							if ( response != '' ) {
								jQuery( '.insight-portfolio-archive .row' ).append( response );
								var query = jQuery.parseJSON( jQuery( '.insight-load-infinity input' ).val() );
								query.paged ++;
								jQuery( '.insight-load-infinity input' ).val( JSON.stringify( query ) );
							} else {
								jQuery( '.insight-load-infinity' ).remove();
							}
						} );
					} );

					// load button
					jQuery( '.insight-load-button' ).on( 'click', function() {
						jQuery( '.insight-load-button span' ).addClass( 'loading' );
						var data = {
							action: 'portfolio_load',
							query: jQuery( '.insight-load-button input' ).val()
						};
						jQuery.post( jsVar.url, data, function( response ) {
							jQuery( '.insight-load-button span' ).removeClass( 'loading' );
							if ( response != '' ) {
								jQuery( '.insight-portfolio-archive .row' ).append( response );
								var query = jQuery.parseJSON( jQuery( '.insight-load-button input' ).val() );
								query.paged ++;
								jQuery( '.insight-load-button input' ).val( JSON.stringify( query ) );
							} else {
								jQuery( '.insight-load-button' ).remove();
							}
						} );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
