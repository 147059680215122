function insight_contact_height() {
	var ww = jQuery( window ).width();
	var wh = jQuery( window ).height();
	if ( ww > 768 ) {
		jQuery( 'body.contact-split .insight-gmaps' ).height( wh );
		jQuery( 'body.contact-split .insight-gmaps' ).addClass( 'split' );
	} else {
		jQuery( 'body.contact-split .insight-gmaps' ).css( 'height', '400px' );
		jQuery( 'body.contact-split .insight-gmaps' ).removeClass( 'split' );
	}
}

(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PageContact: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					insight_contact_height();

					jQuery( window ).on( 'resize', function() {
						insight_contact_height();
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
