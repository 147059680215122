(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PortfolioList: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					if ( jQuery( '.insight-portfolio-list' ).length > 0 ) {
						var insightHoverTimer;
						var insightHoverDelay = 300;
						var insightBg = jQuery( '.insight-portfolio-list-img  div' ).css( 'background-image' );
						insightBg = insightBg.replace( 'url(', '' ).replace( ')', '' );
						jQuery( '.insight-portfolio-list a' ).hover( function() {
							var this_img = jQuery( this ).data( 'img' );
							insightHoverTimer = setTimeout( function() {
								jQuery( '.insight-portfolio-list-img  div' ).attr( 'style', 'opacity:0; background-image: url(' + this_img + ') !important' ).animate( {opacity: 1} );
							}, insightHoverDelay );
						}, function() {
							clearTimeout( insightHoverTimer );
						} );
					}
					jQuery( '.insight-portfolio-list' ).mouseleave( function() {
						jQuery( '.insight-portfolio-list-img  div' ).attr( 'style', 'opacity:0; background-image: url(' + insightBg + ') !important' ).animate( {opacity: 1} );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
